<template>

  <!-- Draft Sections -->
  <v-list
    three-line
    class="draft-sections"
  >

    <!-- Section -->
    <v-list-item
      v-for="section in sectionModels"
      :key="section.slug"
      @click="handleDraftFromSection(section.slug)"
    >

      <!-- Section Name + Description -->
      <v-list-item-content>
        <v-list-item-title
          class="section-name"
          v-html="section.name"
        />
        <v-list-item-content
          class="section-description"
          v-html="section.description"
        />
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftSections',
  // -------------
  // Properties ==
  // -------------
  props: {
    volume: {
      type: String,
      required: true
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'magazine',
      'columns',
      'news'
    ]),
    // Data
    sectionModels() {
      return this[this.volume].getModels()
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handleDraftFromSection (slug) {
      this.$emit('select', { type: 'section', id: slug })
    }
  }
}
</script>
