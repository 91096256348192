<template>

  <!-- Draft Assignments -->
  <v-list
    three-line
    class="draft-assignments"
  >

    <!-- Assignment -->
    <v-list-item
      v-for="assignment in assignments.getModels()"
      :key="assignment.tag19"
      @click="handleDraftFromAssignment(assignment.tag19)"
    >

      <!-- Assignment Title + Section -->
      <v-list-item-content>
        <v-list-item-subtitle
          :style="`color: ${assignment.section.highlight_color};`"
          class="assignment-section"
          v-html="assignment.section.name"
        />
        <v-list-item-title class="assignment-title" >
          <j-label
            :title="assignment.releaseDate"
            color="primary"
            small
            class="mr-1"
          />
          {{ assignment.title }}
        </v-list-item-title>
      </v-list-item-content>

  </v-list-item>
</v-list>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftAssignments',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'assignments'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handleDraftFromAssignment (tag19) {
      this.$emit('select', { type: 'assignment', id: tag19 })
    }
  }
}
</script>
