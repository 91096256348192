<template>

  <!-- Draft Sources Panel -->
  <div id="draft-sources-panel">

    <!-- Source Selection Alert -->
    <j-alert>{{ instructions }}</j-alert>

    <!-- Source Selection Tabs -->
    <v-tabs
      v-model="tab"
      fixed-tabs
      show-arrows
    >
      <j-tab
        href="#assignments"
        icon="list"
        :disabled="disableAssignments"
      >
        Assignments
      </j-tab>

      <j-tab
        href="#magazine"
        icon="file-alt"
      >
        Magazine
      </j-tab>

      <j-tab
        href="#columns"
        icon="book"
      >
        Columns
      </j-tab>

      <j-tab
        href="#news"
        icon="newspaper"
      >
        News
      </j-tab>

      <!-- Assigned Opps -->
      <v-tab-item value="assignments">
        <draft-assignments @select="handleSelect" />
      </v-tab-item>

      <!-- Magazine Sections -->
      <v-tab-item value="magazine">
        <draft-sections volume="magazine" @select="handleSelect" />
      </v-tab-item>

      <!-- Columns -->
      <v-tab-item value="columns">
        <draft-sections volume="columns" @select="handleSelect" />
      </v-tab-item>

      <!-- News -->
      <v-tab-item value="news">
        <draft-sections volume="news" @select="handleSelect" />
      </v-tab-item>

    </v-tabs>
  </div>
</template>

<script>

// Components
import DraftAssignments from '@/components/Drafts/DraftAssignments'
import DraftSections from '@/components/Drafts/DraftSections'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftSourcesView',
  // -------------
  // Components ==
  // -------------
  components: {
    DraftAssignments,
    DraftSections
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      // Get assignments and postable sections
      vm.newContext()
    })
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'assignmentsTab',
      'assignments',
      'draft'
    ]),
    ...mapGetters([
      'draftSection'
    ]),
    tab: {
      get () { return this.assignmentsTab },
      set (value) { this.SET_ASSIGNMENTS_TAB(value) }
    },
    assignmentCount () {
      return this.assignments.getModels().length
    },
    disableAssignments () {
      return this.assignmentCount == 0
    },
    showFormatSelector () {
      return this.draft.sourceType == 'assignment'
        || this.draftSection.volume_slug == 'columns'
    },
    instructions () {
      return this.disableAssignments
        ? 'Choose a blank form from the tabs below.'
        : 'Post an assigned article or choose a blank form from the tabs below.'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'newContext'
    ]),
    // Mutations
    ...mapMutations([
      'SET_ASSIGNMENTS_TAB'
    ]),
    // Handlers
    handleSelect (params) {
      this.$router.push({ name: 'draft-form', params })
    }
  }
}
</script>
